
.block-wrapper {
}

.block-wrapper  input {
	width: 100%;
	padding: 10px;
	border: 1px solid #e4e4e4;
	border-radius: 3px;
	outline: none;
	font-size: 14px;
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}

.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0px;
}

input.invalid {
	border: 1px solid #eb5959cc;
}
.cdxcarousel-addImage {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    position: relative;
    flex-direction: row;
  height: 200px;
}

.cdxcarousel-item--empty .image-tool__image-preloader{
  display: none;
}

.cdxcarousel-item img {
    margin-bottom: 15px;
    display: block;
    object-fit: cover;
    width: 100%;
    order: 1;
}

.cdxcarousel-inputUrl {
    order: 0;
    display: none;
}

.cdxcarousel-caption {
    order: 3;
}


.cdxcarousel-block {
}

.cdxcarousel-list {
}

.cdxcarousel-wrapper {

  .image-tool__caption {
    display: block;
    margin-top: 10px;
  }
}

.cdxcarousel-item {
    /*position: relative;*/
}


.cdxcarousel-removeBtn {
    position: absolute;
    padding: 3px;
    right: 10px;
    top: 10px;
    display: inline-flex;
    width: 34px;
    height: 34px;
    line-height: 34px;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    border: 0;
    outline: none;
    background-color: white;
    color: #000;
    transition: background 200ms;
}

.cdxcarousel-removeBtn:hover {
    background: #eff2f5;
}

.image-tool {

  &__image {
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 10px;

    &-picture {
      max-width: 100%;
      vertical-align: bottom;
      display: block;
    }

    &-preloader {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-size: cover;
      margin: auto;
      position: relative;
      background-color: #cdd1e0;
      background-position: center center;

      &::after {
        content: "";
        position: absolute;
        z-index: 3;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid #cdd1e0;
        border-top-color: #388ae5;
        left: 50%;
        top: 50%;
        margin-top: -30px;
        margin-left: -30px;
        animation: image-preloader-spin 2s infinite linear;
        box-sizing: border-box;
      }
    }
  }
}

/* swiper */
.swiper-pagination {
  display: flex !important;
  flex-grow: 1;
  justify-content: center;
  max-width: 100%;
  margin: 0;
  list-style: none;
  position: static;
  margin-top: 25px;
}
.swiper-pagination-bullet{
  padding: 0;
  width: 40px;
  height: 3px;
  color: transparent;
  font-size: 0;
  background-color: rgba(0, 0, 0, 0.1);
  border: 0 none;
  transition: all 0.3s;
  border-radius: 0;
  opacity: 1;
  &.swiper-pagination-bullet-active {
    background-color: #000;
  }
}

.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 50%;
  bottom: initial;
  margin-top: -20px;
  padding: 10px;
  background-color: transparent;
  border: 0 none;
  color: transparent;
  font-size: 0;
  opacity: 1;
  width: auto;
  height: auto;
}
.swiper-button-next {
  right: 0px;

  &:after {
    content: "";
    display: block;
    width: 8px;
    height: 15px;
    background: transparent url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA5IDE2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA5IDE2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBFMEUwRTt9Cjwvc3R5bGU+Cjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMC45LDE1LjQgMC4xLDE0LjYgNi44LDggMC4xLDEuNCAwLjksMC42IDguMiw4ICIvPgo8L3N2Zz4K) no-repeat;
  }
}
.swiper-button-prev{
  left: 0px;
  right: inherit;
  transform: rotate(180deg) translate(0, 1px);
  &:after {
      content: "";
      display: block;
      width: 8px;
      height: 15px;
      background: transparent url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA5IDE2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA5IDE2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBFMEUwRTt9Cjwvc3R5bGU+Cjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMC45LDE1LjQgMC4xLDE0LjYgNi44LDggMC4xLDEuNCAwLjksMC42IDguMiw4ICIvPgo8L3N2Zz4K) no-repeat;
  }
}

.block--space {
    position: relative;
}

.block--space-info {
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 12px;
    z-index: 1;
    background: #eee;
    padding: 3px 5px;
    border-radius: 3px;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

html, body {background:#eaeaea;}
* {
    box-sizing: border-box;
}

.ce-block {
    padding:0 20px;
    box-sizing: border-box;
    @media screen and (max-width: 768px) {
        padding: 0;
    }
}
.editor-layout{
}

.image-tool__image-picture {
    margin: 0 auto;
}

.editor-layout .ce-block__content{
    width: 100%;
    max-width: 100%;
    //padding: 0 27px;
    box-sizing: border-box;
}

.editor-layout .ce-toolbar__content {
    max-width: 100%;
}

@media screen and (max-width: 1080px) {
    .editor-layout .ce-block__content {
        //padding: 0 20px;
    }
}

@media screen and (max-width: 650px) {
    .editor-layout {
        padding: 0;
    }
    .editor-layout .ce-toolbar__content{
        max-width: calc(100% - 34px);
    }
}


// .codex-editor--narrow .codex-editor__redactor
.codex-editor--narrow .codex-editor__redactor{margin: 0 !important;}

.ce-toolbar{z-index: 10;}

.grab_column__item{border: 1px dashed rgba(0,0,0,.1);}

/* image caption */
.cdx-input.image-tool__caption{border: none;text-align: center;box-shadow: none;padding-top: 10px;font-size: var(--chakra-fontSizes-xs);color: var(--chakra-colors-gray-line);line-height: 1;padding-bottom: 10px;}
.image-tool__caption[contentEditable="true"][data-placeholder]:empty::before{text-align: center;width: 100%;font-size: var(--chakra-fontSizes-xs);color: var(--chakra-colors-gray-line);line-height: 0.8;}
.image-tool__image{margin: 0;}
.grab_blockquote__quote{padding-top: 30px;position: relative}
.grab_blockquote__quote::before{position: absolute;left: 0;top: 0;width: 100%;z-index: 10;background-position: center top;}

/* 슬라이더 하단으로 간격 40px 추가 */
.swiper-container{margin-bottom: 40px;}

.ce-block a{color: var(--chakra-colors-gray-line) !important;text-decoration: underline; text-decoration-color: var(--chakra-colors-gray-line) !important;}


/* link */
.link-tool__content{
    display: block;
    width: 375px;
    overflow: hidden;
    max-width: 100%;
    margin-inline: auto;
    border: 1px solid var(--chakra-colors-gray-200);
    text-decoration: none;
    color: var(--chakra-colors-charcoal);
    font-size: var(--chakra-fontSizes-sm);
    background-color: var(--chakra-colors-cloud);
    position: relative;
    text-decoration: none !important;
    padding:  12px 20px 0 120px;
    height: 100px;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 0;
    background-color: var(--chakra-colors-cloud);

    &:hover {
        box-shadow: none;
    }

    & > * {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px !important;
        line-height: 24px;
        margin: 0;
        color:#000;
    }

    .link-tool__image {
        border-radius: 0;
        position: absolute;
        background:#ddd;
        left: 0;
        top: 0;
        z-index: 10;
        margin: 0;
        width: 100px;
        height: 100px;
        flex: none;
        background-size: cover;
    }
    .link-tool__anchor {
        color: var(--chakra-colors-gray-line);
        text-decoration: underline;
    }
}

.cdx-list {
    font-size: 18px;
    line-height: 1.8;
    .cdx-list__item {
        padding: 0;
        line-height: 1.8;
    }
}

@import './chakra.css';
@import './article.css';

:host, :root {
  --chakra-ring-inset: var(--chakra-empty,/*!*/ /*!*/);
  --chakra-ring-offset-width: 0px;
  --chakra-ring-offset-color: #fff;
  --chakra-ring-color: rgba(66, 153, 225, 0.6);
  --chakra-ring-offset-shadow: 0 0 #0000;
  --chakra-ring-shadow: 0 0 #0000;
  --chakra-space-x-reverse: 0;
  --chakra-space-y-reverse: 0;
  --chakra-colors-transparent: transparent;
  --chakra-colors-current: currentColor;
  --chakra-colors-black: #0e0e0e;
  --chakra-colors-white: #ffffff;
  --chakra-colors-whiteAlpha-50: rgba(255, 255, 255, 0.04);
  --chakra-colors-whiteAlpha-100: rgba(255, 255, 255, 0.06);
  --chakra-colors-whiteAlpha-200: rgba(255, 255, 255, 0.08);
  --chakra-colors-whiteAlpha-300: rgba(255, 255, 255, 0.16);
  --chakra-colors-whiteAlpha-400: rgba(255, 255, 255, 0.24);
  --chakra-colors-whiteAlpha-500: rgba(255, 255, 255, 0.36);
  --chakra-colors-whiteAlpha-600: rgba(255, 255, 255, 0.48);
  --chakra-colors-whiteAlpha-700: rgba(255, 255, 255, 0.64);
  --chakra-colors-whiteAlpha-800: rgba(255, 255, 255, 0.80);
  --chakra-colors-whiteAlpha-900: rgba(255, 255, 255, 0.92);
  --chakra-colors-blackAlpha-50: rgba(0, 0, 0, 0.04);
  --chakra-colors-blackAlpha-100: rgba(0, 0, 0, 0.06);
  --chakra-colors-blackAlpha-200: rgba(0, 0, 0, 0.08);
  --chakra-colors-blackAlpha-300: rgba(0, 0, 0, 0.16);
  --chakra-colors-blackAlpha-400: rgba(0, 0, 0, 0.24);
  --chakra-colors-blackAlpha-500: rgba(0, 0, 0, 0.36);
  --chakra-colors-blackAlpha-600: rgba(0, 0, 0, 0.48);
  --chakra-colors-blackAlpha-700: rgba(0, 0, 0, 0.64);
  --chakra-colors-blackAlpha-800: rgba(0, 0, 0, 0.80);
  --chakra-colors-blackAlpha-900: rgba(0, 0, 0, 0.92);
  --chakra-colors-gray-50: #f8fafa;
  --chakra-colors-gray-100: #f0f4f4;
  --chakra-colors-gray-200: #e6eaeb;
  --chakra-colors-gray-300: #d2d9d9;
  --chakra-colors-gray-400: #aab5b6;
  --chakra-colors-gray-500: #7d898b;
  --chakra-colors-gray-600: #545d5f;
  --chakra-colors-gray-700: #353e3f;
  --chakra-colors-gray-800: #1f2426;
  --chakra-colors-gray-900: #1b1e20;
  --chakra-colors-gray-bg: #f4f4f4;
  --chakra-colors-gray-light: #e9e9e9;
  --chakra-colors-gray-line: #b3b3b3;
  --chakra-colors-gray-transparent: rgba(0, 0, 0, 0.3);
  --chakra-colors-red-50: #FFF5F5;
  --chakra-colors-red-100: #FED7D7;
  --chakra-colors-red-200: #FEB2B2;
  --chakra-colors-red-300: #FC8181;
  --chakra-colors-red-400: #F56565;
  --chakra-colors-red-500: #E53E3E;
  --chakra-colors-red-600: #C53030;
  --chakra-colors-red-700: #9B2C2C;
  --chakra-colors-red-800: #822727;
  --chakra-colors-red-900: #63171B;
  --chakra-colors-orange-50: #FFFAF0;
  --chakra-colors-orange-100: #FEEBC8;
  --chakra-colors-orange-200: #FBD38D;
  --chakra-colors-orange-300: #F6AD55;
  --chakra-colors-orange-400: #ED8936;
  --chakra-colors-orange-500: #DD6B20;
  --chakra-colors-orange-600: #C05621;
  --chakra-colors-orange-700: #9C4221;
  --chakra-colors-orange-800: #7B341E;
  --chakra-colors-orange-900: #652B19;
  --chakra-colors-yellow-50: #FFFFF0;
  --chakra-colors-yellow-100: #FEFCBF;
  --chakra-colors-yellow-200: #FAF089;
  --chakra-colors-yellow-300: #F6E05E;
  --chakra-colors-yellow-400: #ECC94B;
  --chakra-colors-yellow-500: #D69E2E;
  --chakra-colors-yellow-600: #B7791F;
  --chakra-colors-yellow-700: #975A16;
  --chakra-colors-yellow-800: #744210;
  --chakra-colors-yellow-900: #5F370E;
  --chakra-colors-green-50: #F0FFF4;
  --chakra-colors-green-100: #C6F6D5;
  --chakra-colors-green-200: #9AE6B4;
  --chakra-colors-green-300: #68D391;
  --chakra-colors-green-400: #48BB78;
  --chakra-colors-green-500: #38A169;
  --chakra-colors-green-600: #2F855A;
  --chakra-colors-green-700: #276749;
  --chakra-colors-green-800: #22543D;
  --chakra-colors-green-900: #1C4532;
  --chakra-colors-teal-50: #b9d9d3;
  --chakra-colors-teal-100: #87decf;
  --chakra-colors-teal-200: #5adcca;
  --chakra-colors-teal-300: #33d2c1;
  --chakra-colors-teal-400: #25b4ae;
  --chakra-colors-teal-500: #009a9e;
  --chakra-colors-teal-600: #1b787e;
  --chakra-colors-teal-700: #185b61;
  --chakra-colors-teal-800: #134953;
  --chakra-colors-teal-900: #113c44;
  --chakra-colors-blue-50: #ebf8ff;
  --chakra-colors-blue-100: #bee3f8;
  --chakra-colors-blue-200: #90cdf4;
  --chakra-colors-blue-300: #63b3ed;
  --chakra-colors-blue-400: #4299e1;
  --chakra-colors-blue-500: #3182ce;
  --chakra-colors-blue-600: #2b6cb0;
  --chakra-colors-blue-700: #2c5282;
  --chakra-colors-blue-800: #2a4365;
  --chakra-colors-blue-900: #1A365D;
  --chakra-colors-cyan-50: #EDFDFD;
  --chakra-colors-cyan-100: #C4F1F9;
  --chakra-colors-cyan-200: #9DECF9;
  --chakra-colors-cyan-300: #76E4F7;
  --chakra-colors-cyan-400: #0BC5EA;
  --chakra-colors-cyan-500: #00B5D8;
  --chakra-colors-cyan-600: #00A3C4;
  --chakra-colors-cyan-700: #0987A0;
  --chakra-colors-cyan-800: #086F83;
  --chakra-colors-cyan-900: #065666;
  --chakra-colors-purple-50: #FAF5FF;
  --chakra-colors-purple-100: #E9D8FD;
  --chakra-colors-purple-200: #D6BCFA;
  --chakra-colors-purple-300: #B794F4;
  --chakra-colors-purple-400: #9F7AEA;
  --chakra-colors-purple-500: #805AD5;
  --chakra-colors-purple-600: #6B46C1;
  --chakra-colors-purple-700: #553C9A;
  --chakra-colors-purple-800: #44337A;
  --chakra-colors-purple-900: #322659;
  --chakra-colors-pink-50: #FFF5F7;
  --chakra-colors-pink-100: #FED7E2;
  --chakra-colors-pink-200: #FBB6CE;
  --chakra-colors-pink-300: #F687B3;
  --chakra-colors-pink-400: #ED64A6;
  --chakra-colors-pink-500: #D53F8C;
  --chakra-colors-pink-600: #B83280;
  --chakra-colors-pink-700: #97266D;
  --chakra-colors-pink-800: #702459;
  --chakra-colors-pink-900: #521B41;
  --chakra-colors-linkedin-50: #E8F4F9;
  --chakra-colors-linkedin-100: #CFEDFB;
  --chakra-colors-linkedin-200: #9BDAF3;
  --chakra-colors-linkedin-300: #68C7EC;
  --chakra-colors-linkedin-400: #34B3E4;
  --chakra-colors-linkedin-500: #00A0DC;
  --chakra-colors-linkedin-600: #008CC9;
  --chakra-colors-linkedin-700: #0077B5;
  --chakra-colors-linkedin-800: #005E93;
  --chakra-colors-linkedin-900: #004471;
  --chakra-colors-facebook-50: #E8F4F9;
  --chakra-colors-facebook-100: #D9DEE9;
  --chakra-colors-facebook-200: #B7C2DA;
  --chakra-colors-facebook-300: #6482C0;
  --chakra-colors-facebook-400: #4267B2;
  --chakra-colors-facebook-500: #385898;
  --chakra-colors-facebook-600: #314E89;
  --chakra-colors-facebook-700: #29487D;
  --chakra-colors-facebook-800: #223B67;
  --chakra-colors-facebook-900: #1E355B;
  --chakra-colors-messenger-50: #D0E6FF;
  --chakra-colors-messenger-100: #B9DAFF;
  --chakra-colors-messenger-200: #A2CDFF;
  --chakra-colors-messenger-300: #7AB8FF;
  --chakra-colors-messenger-400: #2E90FF;
  --chakra-colors-messenger-500: #0078FF;
  --chakra-colors-messenger-600: #0063D1;
  --chakra-colors-messenger-700: #0052AC;
  --chakra-colors-messenger-800: #003C7E;
  --chakra-colors-messenger-900: #002C5C;
  --chakra-colors-whatsapp-50: #dffeec;
  --chakra-colors-whatsapp-100: #b9f5d0;
  --chakra-colors-whatsapp-200: #90edb3;
  --chakra-colors-whatsapp-300: #65e495;
  --chakra-colors-whatsapp-400: #3cdd78;
  --chakra-colors-whatsapp-500: #22c35e;
  --chakra-colors-whatsapp-600: #179848;
  --chakra-colors-whatsapp-700: #0c6c33;
  --chakra-colors-whatsapp-800: #01421c;
  --chakra-colors-whatsapp-900: #001803;
  --chakra-colors-twitter-50: #E5F4FD;
  --chakra-colors-twitter-100: #C8E9FB;
  --chakra-colors-twitter-200: #A8DCFA;
  --chakra-colors-twitter-300: #83CDF7;
  --chakra-colors-twitter-400: #57BBF5;
  --chakra-colors-twitter-500: #1DA1F2;
  --chakra-colors-twitter-600: #1A94DA;
  --chakra-colors-twitter-700: #1681BF;
  --chakra-colors-twitter-800: #136B9E;
  --chakra-colors-twitter-900: #0D4D71;
  --chakra-colors-telegram-50: #E3F2F9;
  --chakra-colors-telegram-100: #C5E4F3;
  --chakra-colors-telegram-200: #A2D4EC;
  --chakra-colors-telegram-300: #7AC1E4;
  --chakra-colors-telegram-400: #47A9DA;
  --chakra-colors-telegram-500: #0088CC;
  --chakra-colors-telegram-600: #007AB8;
  --chakra-colors-telegram-700: #006BA1;
  --chakra-colors-telegram-800: #005885;
  --chakra-colors-telegram-900: #003F5E;
  --chakra-colors-primary: #009a9e;
  --chakra-colors-primaryHover: #008b8e;
  --chakra-colors-charcoal: #484848;
  --chakra-colors-charcoalHover: #333333;
  --chakra-colors-cloud: #fafcfe;
  --chakra-borders-none: 0;
  --chakra-borders-1px: 1px solid;
  --chakra-borders-2px: 2px solid;
  --chakra-borders-4px: 4px solid;
  --chakra-borders-8px: 8px solid;
  --chakra-fonts-heading: 'Noto Sans KR',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
  --chakra-fonts-body: 'Noto Sans KR',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol';
  --chakra-fonts-mono: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --chakra-fontSizes-xs: 12px;
  --chakra-fontSizes-sm: 13px;
  --chakra-fontSizes-md: 15px;
  --chakra-fontSizes-lg: 16px;
  --chakra-fontSizes-xl: 20px;
  --chakra-fontSizes-2xl: 52px;
  --chakra-fontSizes-3xl: 28px;
  --chakra-fontSizes-4xl: 36px;
  --chakra-fontSizes-5xl: 48px;
  --chakra-fontSizes-6xl: 64px;
  --chakra-fontSizes-7xl: 4.5rem;
  --chakra-fontSizes-8xl: 6rem;
  --chakra-fontSizes-9xl: 8rem;
  --chakra-fontWeights-hairline: 100;
  --chakra-fontWeights-thin: 200;
  --chakra-fontWeights-light: 300;
  --chakra-fontWeights-normal: 400;
  --chakra-fontWeights-medium: 500;
  --chakra-fontWeights-semibold: 600;
  --chakra-fontWeights-bold: 700;
  --chakra-fontWeights-extrabold: 800;
  --chakra-fontWeights-black: 900;
  --chakra-letterSpacings-tighter: -0.05em;
  --chakra-letterSpacings-tight: -0.025em;
  --chakra-letterSpacings-normal: -0.01em;
  --chakra-letterSpacings-wide: 0.025em;
  --chakra-letterSpacings-wider: 0.05em;
  --chakra-letterSpacings-widest: 0.1em;
  --chakra-lineHeights-3: .75rem;
  --chakra-lineHeights-4: 1rem;
  --chakra-lineHeights-5: 1.25rem;
  --chakra-lineHeights-6: 1.5rem;
  --chakra-lineHeights-7: 1.75rem;
  --chakra-lineHeights-8: 2rem;
  --chakra-lineHeights-9: 2.25rem;
  --chakra-lineHeights-10: 2.5rem;
  --chakra-lineHeights-normal: normal;
  --chakra-lineHeights-none: 1;
  --chakra-lineHeights-shorter: 1.25;
  --chakra-lineHeights-short: 1.375;
  --chakra-lineHeights-base: 1.5;
  --chakra-lineHeights-tall: 1.625;
  --chakra-lineHeights-taller: 2;
  --chakra-radii-none: 0;
  --chakra-radii-sm: 0.125rem;
  --chakra-radii-base: 0.25rem;
  --chakra-radii-md: 0.375rem;
  --chakra-radii-lg: 0.5rem;
  --chakra-radii-xl: 0.75rem;
  --chakra-radii-2xl: 1rem;
  --chakra-radii-3xl: 1.5rem;
  --chakra-radii-full: 9999px;
  --chakra-space-1: 0.25rem;
  --chakra-space-2: 0.5rem;
  --chakra-space-3: 0.75rem;
  --chakra-space-4: 1rem;
  --chakra-space-5: 1.25rem;
  --chakra-space-6: 1.5rem;
  --chakra-space-7: 1.75rem;
  --chakra-space-8: 2rem;
  --chakra-space-9: 2.25rem;
  --chakra-space-10: 2.5rem;
  --chakra-space-12: 3rem;
  --chakra-space-14: 3.5rem;
  --chakra-space-16: 4rem;
  --chakra-space-20: 5rem;
  --chakra-space-24: 6rem;
  --chakra-space-28: 7rem;
  --chakra-space-32: 8rem;
  --chakra-space-36: 9rem;
  --chakra-space-40: 10rem;
  --chakra-space-44: 11rem;
  --chakra-space-48: 12rem;
  --chakra-space-52: 13rem;
  --chakra-space-56: 14rem;
  --chakra-space-60: 15rem;
  --chakra-space-64: 16rem;
  --chakra-space-72: 18rem;
  --chakra-space-80: 20rem;
  --chakra-space-96: 24rem;
  --chakra-space-px: 1px;
  --chakra-space-0\.5: 0.125rem;
  --chakra-space-1\.5: 0.375rem;
  --chakra-space-2\.5: 0.625rem;
  --chakra-space-3\.5: 0.875rem;
  --chakra-shadows-xs: 0 0 0 1px rgba(0, 0, 0, 0.05);
  --chakra-shadows-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --chakra-shadows-base: 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --chakra-shadows-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --chakra-shadows-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --chakra-shadows-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1),0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --chakra-shadows-2xl: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --chakra-shadows-outline: 0 0 0 2px rgba(0, 154, 158, 0.3);
  --chakra-shadows-inner: inset 0 2px 4px 0 rgba(0,0,0,0.06);
  --chakra-shadows-none: none;
  --chakra-shadows-dark-lg: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,rgba(0, 0, 0, 0.2) 0px 5px 10px,rgba(0, 0, 0, 0.4) 0px 15px 40px;
  --chakra-sizes-1: 0.25rem;
  --chakra-sizes-2: 0.5rem;
  --chakra-sizes-3: 0.75rem;
  --chakra-sizes-4: 1rem;
  --chakra-sizes-5: 1.25rem;
  --chakra-sizes-6: 1.5rem;
  --chakra-sizes-7: 1.75rem;
  --chakra-sizes-8: 2rem;
  --chakra-sizes-9: 2.25rem;
  --chakra-sizes-10: 2.5rem;
  --chakra-sizes-12: 3rem;
  --chakra-sizes-14: 3.5rem;
  --chakra-sizes-16: 4rem;
  --chakra-sizes-20: 5rem;
  --chakra-sizes-24: 6rem;
  --chakra-sizes-28: 7rem;
  --chakra-sizes-32: 8rem;
  --chakra-sizes-36: 9rem;
  --chakra-sizes-40: 10rem;
  --chakra-sizes-44: 11rem;
  --chakra-sizes-48: 12rem;
  --chakra-sizes-52: 13rem;
  --chakra-sizes-56: 14rem;
  --chakra-sizes-60: 15rem;
  --chakra-sizes-64: 16rem;
  --chakra-sizes-72: 18rem;
  --chakra-sizes-80: 20rem;
  --chakra-sizes-96: 24rem;
  --chakra-sizes-px: 1px;
  --chakra-sizes-0\.5: 0.125rem;
  --chakra-sizes-1\.5: 0.375rem;
  --chakra-sizes-2\.5: 0.625rem;
  --chakra-sizes-3\.5: 0.875rem;
  --chakra-sizes-max: max-content;
  --chakra-sizes-min: min-content;
  --chakra-sizes-full: 100%;
  --chakra-sizes-3xs: 14rem;
  --chakra-sizes-2xs: 16rem;
  --chakra-sizes-xs: 20rem;
  --chakra-sizes-sm: 24rem;
  --chakra-sizes-md: 28rem;
  --chakra-sizes-lg: 32rem;
  --chakra-sizes-xl: 36rem;
  --chakra-sizes-2xl: 42rem;
  --chakra-sizes-3xl: 48rem;
  --chakra-sizes-4xl: 56rem;
  --chakra-sizes-5xl: 64rem;
  --chakra-sizes-6xl: 72rem;
  --chakra-sizes-7xl: 80rem;
  --chakra-sizes-8xl: 90rem;
  --chakra-sizes-container-sm: 680px;
  --chakra-sizes-container-md: 768px;
  --chakra-sizes-container-lg: 1024px;
  --chakra-sizes-container-xl: 1482px;
  --chakra-sizes-container-xs: 520px;
  --chakra-zIndices-hide: -1;
  --chakra-zIndices-auto: auto;
  --chakra-zIndices-base: 0;
  --chakra-zIndices-docked: 10;
  --chakra-zIndices-dropdown: 1000;
  --chakra-zIndices-sticky: 1100;
  --chakra-zIndices-banner: 1200;
  --chakra-zIndices-overlay: 1300;
  --chakra-zIndices-modal: 1400;
  --chakra-zIndices-popover: 1500;
  --chakra-zIndices-skipLink: 1600;
  --chakra-zIndices-toast: 1700;
  --chakra-zIndices-tooltip: 1800;
  --chakra-transition-property-common: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
  --chakra-transition-property-colors: background-color,border-color,color,fill,stroke;
  --chakra-transition-property-dimensions: width,height;
  --chakra-transition-property-position: left,right,top,bottom;
  --chakra-transition-property-background: background-color,background-image,background-position;
  --chakra-transition-easing-ease-in: cubic-bezier(0.4, 0, 1, 1);
  --chakra-transition-easing-ease-out: cubic-bezier(0, 0, 0.2, 1);
  --chakra-transition-easing-ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
  --chakra-transition-duration-ultra-fast: 50ms;
  --chakra-transition-duration-faster: 100ms;
  --chakra-transition-duration-fast: 150ms;
  --chakra-transition-duration-normal: 200ms;
  --chakra-transition-duration-slow: 300ms;
  --chakra-transition-duration-slower: 400ms;
  --chakra-transition-duration-ultra-slow: 500ms;
  --chakra-blur-none: 0;
  --chakra-blur-sm: 4px;
  --chakra-blur-base: 8px;
  --chakra-blur-md: 12px;
  --chakra-blur-lg: 16px;
  --chakra-blur-xl: 24px;
  --chakra-blur-2xl: 40px;
  --chakra-blur-3xl: 64px;
}

body, blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0px;
}

hr {
  border-top-width: 1px;
  box-sizing: content-box;
  height: 0px;
  overflow: visible;
}
html, body {
  margin: 0;
  font-family: 'Noto Sans KR', sans-serif !important;
  letter-spacing: -0.03em;
  line-height: 1.6;
  word-wrap: break-word;
  word-break: keep-all;
  font-size: var(--chakra-fontSizes-md);
}

/**
 * Spacing
 */

.grab_module {
  font-size: 18px;
  line-height: 1.8;
}

/*.grab_module:first-of-type,*/
/*.grab_module+.grab_module,*/
/*[class^=grab_paragraph]+[class^=grab_paragraph] {*/
/*  margin-top: 35px;*/
/*}*/

/*.grab_module+[class^=grab_link_preview],*/
/*.grab_module+[class^=grab_blockquote__],*/
/*.grab_module+[class^=grab_video],*/
/*.grab_module+[class^=grab_linebox],*/
/*.grab_module+[class^=grab-divider] {*/
/*  margin-top: 40px;*/
/*}*/

/*.grab_module+[class^=grab_slide],*/
/*.grab_column+.grab_column,*/
/*[class^=grab-divider]+.grab_module {*/
/*  margin-top: 44px;*/
/*}*/

/*[class^=grab_heading__]+[class^=grab_paragraph],*/
/*[class^=grab_heading__]+[class^=grab_list] {*/
/*  margin-top: 10px;*/
/*}*/

/**
 * Heading
 */

[class^=grab_heading__] {
  margin: 0;
  font-weight: 700;
  line-height: 1.4;
}

.grab_heading__1 {
  font-size: 32px;
  font-weight: 900;
}

.grab_heading__2 {
  font-size: 28px;
}

.grab_heading__3 {
  font-size: 22px;
}

/**
 * Paragraph
 */

.grab_paragraph__md {
  font-size: 18px;
}

.grab_paragraph__sm {
  font-size: 16px;
}

[class^=grab_paragraph__] a {
  color: var(--chakra-colors-gray-line);
  text-decoration: underline;
}

/*
  Divider
  */

.grab-divider__full {
  border-width: 0px 0px 1px;
  border-image: initial;
  border-color: var(--chakra-colors-gray-200);
  border-style: solid;
  width: 100%;
}

.grab-divider__short {
  margin-inline: auto;
  width: 80px;
  border-width: 0px 0px 4px;
  border-image: initial;
  border-color: var(--chakra-colors-gray-700);
  border-style: solid;
}

/**
 * Column
 */

.grab_column {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
}

.grab_column__item {
  width: 100%;
  flex-grow: 1;
}

.grab_column__item:first-child {
  margin-right: 15px;
}

.grab_column__item:last-child {
  margin-left: 15px;
}

.grab_column__item > [class^=grab_paragraph]:first-of-type {
  margin-top: -0.525em;
}

/**
 * Preview Link
 */

 .grab_link_preview {
  width: 375px;
  max-width: 100%;
  margin-inline: auto;
}

.grab_link_preview__wrap {
  display: flex;
  flex-grow: 1;
  border: 1px solid var(--chakra-colors-gray-200);
  text-decoration: none;
  color: var(--chakra-colors-charcoal);
  font-size: var(--chakra-fontSizes-sm);
  background-color: var(--chakra-colors-cloud);
}

.grab_link_preview__image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.grab_link_preview__content {
  padding-top: 12px;
  padding-left: 20px;
  width: 100%;
  line-height: 1.8;
}

.grab_link_preview__title {
  display: block;
}

.grab_link_preview__desc {
  display: block;
}

.grab_link_preview__url {
  display: block;
  color: var(--chakra-colors-gray-line);
  text-decoration: underline;
}

/**
 * Image
 */

.grab_image img {
  display: block;
  width: 100%;
  max-width: 100%;
}

.grab_image__meta {
  padding-top: 10px;
  font-size: var(--chakra-fontSizes-xs);
  color: var(--chakra-colors-gray-line);
  line-height: 0.8;
  text-align: center;
}

/**
 * Blockquote
 */

.grab_blockquote__quote {
  text-align: center;
}

.grab_blockquote__quote::before {
  content: "";
  display: block;
  margin-inline: auto;
  margin-bottom: 10px;
  width: 28px;
  height: 20px;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOCAyMCI+PHBvbHlnb24gcG9pbnRzPSIyNiAwIDIwIDAgMTYgOCAxNiAyMCAyOCAyMCAyOCA4IDIyIDggMjYgMCIgc3R5bGU9ImZpbGw6IzBlMGUwZSIvPjxwb2x5Z29uIHBvaW50cz0iMTAgMCA0IDAgMCA4IDAgMjAgMTIgMjAgMTIgOCA2IDggMTAgMCIgc3R5bGU9ImZpbGw6IzBlMGUwZSIvPjwvc3ZnPg==") no-repeat;
  text-align: center;
}

.grab_blockquote__line {
  position: relative;
  padding-left: 30px;
  /* border-left: 4px solid var(--chakra-colors-gray-line); */
}

.grab_blockquote__line::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.5em;
  width: 4px;
  height: calc(100% - 0.8em);
  background-color: var(--chakra-colors-gray-line);
}

/**
 * List
 */

[class^=grab_list__] {
  margin-bottom: 0;
}

/**
 * Video
 */

.grab-video > iframe:first-child {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

.grab-video__url {
  padding-top: 10px;
  font-size: var(--chakra-fontSizes-xs);
  color: var(--chakra-colors-gray-line);
  line-height: 0.8;
  text-align: center;
}

/**
 * Line Box
 */

.grab_linebox {
  padding: 25px 30px;
  border: 1px solid var(--chakra-colors-gray-300);
}

/**
 * Slide
 */

.grab_slide__item > img {
  display: block;
  width: 100%;
  height: auto;
  aspect-ratio: 78 / 55;
  object-fit: cover;
}

.grab_slide .slick-arrow {
  position: absolute;
  top: 50%;
  bottom: initial;
  margin-top: -20px;
  padding: 10px;
  background-color: transparent;
  border: 0 none;
  color: transparent;
  font-size: 0;
  opacity: 1;
}

.grab_slide .slick-arrow::before {
  content: "";
  display: block;
  width: 8px;
  height: 15px;
  background: transparent url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA5IDE2IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA5IDE2OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzBFMEUwRTt9Cjwvc3R5bGU+Cjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iMC45LDE1LjQgMC4xLDE0LjYgNi44LDggMC4xLDEuNCAwLjksMC42IDguMiw4ICIvPgo8L3N2Zz4K") no-repeat;
}

.grab_slide .slick-arrow.slick-next {
  right: -30px;
}

.grab_slide .slick-arrow.slick-prev {
  left: -30px;
  right: inherit;
}

.grab_slide .slick-arrow.slick-prev::before {
  transform: rotate(180deg) translate(0, 1px);
}

.grab_slide .slick-dots {
  display: flex !important;
  flex-grow: 1;
  justify-content: center;
  max-width: 100%;
  margin: 0;
  list-style: none;
}

.grab_slide .slick-dots li {
  margin-inline: 3px;
}

.grab_slide .slick-dots li button {
  padding: 0;
  width: 40px;
  height: 3px;
  color: transparent;
  font-size: 0;
  background-color: rgba(0, 0, 0, 0.1);
  border: 0 none;
  transition: all 0.3s;
}

.grab_slide .slick-dots li.slick-active button {
  background-color: black;
}

/**
 * Responsive Layout
 */

@media (max-width: 768px) {
  .grab_module {
    font-size: 16px;
  }

  .grab_heading__1 {
    font-size: 28px;
  }

  .grab_heading__2 {
    font-size: 24px;
  }

  .grab_heading__3 {
    font-size: 20px;
  }

  .grab_paragraph__sm {
    font-size: 14px;
  }

  .grab_column {
    display: block;
  }

  .grab_column__item+.grab_column__item {
    margin-top: 35px;
  }

  .grab_column__item:first-child {
    margin-right: initial;
  }

  .grab_column__item:last-child {
    margin-left: initial;
  }

  .grab_column__item > [class^=grab_paragraph]:first-of-type {
    margin-top: 0;
  }

  .grab_blockquote__line {
    padding-left: 25px;
  }

  .grab_slide .slick-arrow {
    top: initial;
    bottom: -32px;
    opacity: 0.3;
  }

  .grab_slide .slick-arrow.slick-next {
    right: -10px;
  }

  .grab_slide .slick-arrow.slick-prev {
    left: inherit;
    right: 10px;
  }

  .grab_linebox {
    padding: 18px 25px;
  }
}


.cdxcarousel-item img {
  max-height: 600px;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
}
